import Bpage4 from "../../components/blog-pages/Bpage4";
import Header from "../../components/Header";
import Sobre from "../../components/Sobre";

function Page4() {
    return (
        <div>
            <Header isComunicacao={false}></Header>
            <Bpage4></Bpage4>
            <Sobre></Sobre>
        </div>
    )

}

export default Page4;