import "./Clientes.scss"

import logo1 from "../images/clientes/logo1.jpg"
import logo2 from "../images/clientes/logo2.jpg"
import logo3 from "../images/clientes/logo3.jpg"
import logo4 from "../images/clientes/logo4.jpg"
import logo5 from "../images/clientes/logo5.jpg"
import logo6 from "../images/clientes/logo6.jpg"
import logo7 from "../images/clientes/logo7.jpg"
import logo8 from "../images/clientes/logo8.jpg"
import logo9 from "../images/clientes/logo9.jpg"
import logo10 from "../images/clientes/logo10.jpg"
import logo11 from "../images/clientes/logo11.jpg"
import logo12 from "../images/clientes/logo12.jpg"
import logo13 from "../images/clientes/logo13.jpg"
import logo14 from "../images/clientes/logo14.jpg"
import logo15 from "../images/clientes/logo15.jpg"
import logo16 from "../images/clientes/logo16.jpg"
import logo17 from "../images/clientes/logo17.jpg"
import logo18 from "../images/clientes/logo18.jpg"

function Clientes () {
            return (
                <div id="clientes">
                    <h2>Nossos Clientes</h2>
                    <div className="wrap-logos">
                        <div className="logo">
                            <img src={logo1} alt="Logo Cliente Albert Einstein"/>
                        </div>
                        <div className="logo">
                            <img src={logo2} alt="Logo Cliente Rema Tip Top"/>
                        </div>
                        <div className="logo">
                            <img src={logo3} alt="Logo Cliente Staff Brasil"/>
                        </div>
                        <div className="logo">
                            <img src={logo4} alt="Logo Cliente Civil Master"/>
                        </div>
                        <div className="logo">
                            <img src={logo5} alt="Logo Cliente Mabu Aviamentos"/>
                        </div>
                        <div className="logo">
                            <img src={logo6} alt="Logo Cliente Pacaembu Construtora"/>
                        </div>
                        <div className="logo">
                            <img src={logo7} alt="Logo Cliente Cristal & Cores"/>
                        </div>
                        <div className="logo">
                            <img src={logo8} alt="Logo Cliente Show Auto Mall"/>
                        </div>
                        <div className="logo">
                            <img src={logo9} alt="Logo Cliente Distribuidora Centauro"/>
                        </div>
                        <div className="logo">
                            <img src={logo10} alt="Logo Cliente Casa Giacomo"/>
                        </div>
                        <div className="logo">
                            <img src={logo11} alt="Logo Cliente Bônus Solar"/>
                        </div>
                        <div className="logo">
                            <img src={logo12} alt="Logo Cliente Lups Esportes kids e Teens"/>
                        </div>
                        <div className="logo">
                            <img src={logo13} alt="Logo Cliente Moramassa"/>
                        </div>
                        <div className="logo">
                            <img src={logo14} alt="Logo Cliente Ativa Telecom"/>
                        </div>
                        <div className="logo">
                            <img src={logo15} alt="Logo Cliente Estrela do Céu"/>
                        </div>
                        <div className="logo">
                            <img src={logo16} alt="Logo Cliente Compact Store"/>
                        </div>
                        <div className="logo">
                            <img src={logo17} alt="Logo Cliente Nesa Cursos"/>
                        </div>
                        <div className="logo">
                            <img src={logo18} alt="Logo Cliente Xavier & Melo"/>
                        </div>
                    </div>

                </div>
            );
}

export default Clientes;