import { NavLink } from 'react-router-dom'
import './NavePages.scss'
import PlusIcon from '../../images/plus.png'

function NavPage(props) {



    function scrollToTop() {
        window.scrollTo(0, 0)
    }

    return (
        <div className='NavPagesBlog'>
            <nav className='indent'>
                <h2>Outros assuntos</h2>
                <ul>
                    {props.hide === '1' ? null : (
                        <li>
                            <NavLink to='/criacao-de-logos-profissionais' onClick={scrollToTop}>
                                <p>Por Que o Logotipo é o Primeiro Passo para o Sucesso da Sua Marca?</p>
                                <img src={PlusIcon} alt='Saiba Mais'></img>
                            </NavLink>
                        </li>
                    )}
                    {props.hide === '2' ? null : (
                        <li>
                            <NavLink to='/criacao-de-identidade-visual' onClick={scrollToTop}>
                                <p>Identidade Visual: Mais do que Estética, uma Estratégia de Marca</p>
                                <img src={PlusIcon} alt='Saiba Mais'></img>
                            </NavLink>
                        </li>
                    )}
                    {props.hide === '3' ? null : (
                        <li>
                            <NavLink to='/videos-motion-graphics-dinamicos' onClick={scrollToTop}>
                                <p>Motion Graphics: A Ferramenta Perfeita para Engajar seu Público</p>
                                <img src={PlusIcon} alt='Saiba Mais'></img>
                            </NavLink>
                        </li>
                    )}
                    {props.hide === '4' ? null : (
                        <li>
                            <NavLink to='/web-design-experiencia-digital' onClick={scrollToTop}>
                                <p>Web Design que Alinha Beleza e Funcionalidade</p>
                                <img src={PlusIcon} alt='Saiba Mais'></img>
                            </NavLink>
                        </li>
                    )}
                    {props.hide === '5' ? null : (
                        <li>
                            <NavLink to='/criacao-de-sites-profissionais' onClick={scrollToTop}>
                                <p>Por Que Investir em um Site Profissional é Essencial?</p>
                                <img src={PlusIcon} alt='Saiba Mais'></img>
                            </NavLink>
                        </li>
                    )}
                    {props.hide === '6' ? null : (
                        <li>
                            <NavLink to='/criacao-de-fantasias-personalizadas' onClick={scrollToTop}>
                                <p>Fantasias e Mascotes que Transformam sua Marca</p>
                                <img src={PlusIcon} alt='Saiba Mais'></img>
                            </NavLink>
                        </li>
                    )}

                </ul>
            </nav>
        </div>
    );
}

export default NavPage;