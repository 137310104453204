import React from "react";
import './identidades.scss'
import img1 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n1.jpg'
import img2 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n2.jpg'
import img3 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n3.jpg'
import img4 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n4.jpg'
import img5 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n5.jpg'
import img6 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n6.jpg'
import img7 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n7.jpg'
import img8 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n8.jpg'
import img9 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n9.jpg'
import img10 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n10.jpg'
import img11 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n11.jpg'
import img12 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n12.jpg'
import img13 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n13.jpg'
import img14 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n14.jpg'
import img15 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n15.jpg'
import img16 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n16.jpg'
import img17 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n17.jpg'
import img18 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n18.jpg'
import img19 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n19.jpg'
import img20 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n20.jpg'
import img21 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n21.jpg'
import img22 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n22.jpg'
import img23 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n23.jpg'
import img24 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n24.jpg'
import img25 from '../../images/identidades/mabu/desktop/identidade-visual-criada-mabu-aviamentos-n25.jpg'

function Mabu() {
    return (
        <div className="presentation">
            <img src={img1} alt="Identidade Visual e Logo da Mabu Aviamentos" />
            <img src={img2} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img3} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img4} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img5} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img6} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img7} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img8} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img9} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img10} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img11} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img12} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img13} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img14} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img15} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img16} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img17} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img18} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img19} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img20} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img21} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img22} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img23} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img24} alt="Mabu Aviamentos Identidade Visual Criação" />
            <img src={img25} alt="Mabu Aviamentos Identidade Visual Criação" />
        </div>
    );
}

export default Mabu;