import Selaria from '../../components/identidades/Selaria';
import Header from '../../components/Header'

function SelariaPage() {
    window.scrollTo(0, 0);
    return (
        <div>
            <Header isComunicacao={true}></Header>
            <Selaria />
        </div>
    )
}

export default SelariaPage;