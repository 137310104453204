import Bpage2 from "../../components/blog-pages/Bpage2";
import Header from "../../components/Header";
import Sobre from "../../components/Sobre";

function Page2() {
    return (
        <div>
            <Header isComunicacao={false}></Header>
            <Bpage2></Bpage2>
            <Sobre></Sobre>
        </div>
    )

}

export default Page2;