import Header from '../../components/Header'
import Staff from '../../components/identidades/Staff';

function StaffPage() {
    window.scrollTo(0, 0);
    return (
        <div>
            <Header isComunicacao={true}></Header>
            <Staff />
        </div>
    )
}

export default StaffPage;