import React from "react";
import './identidades.scss'
import img1 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero1.jpg'
import img2 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero2.jpg'
import img3 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero3.jpg'
import img4 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero4.jpg'
import img5 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero5.jpg'
import img6 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero6.jpg'
import img7 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero7.jpg'
import img8 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero8.jpg'
import img9 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero9.jpg'
import img10 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero10.jpg'
import img11 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero11.jpg'
/* import img12 from '../../images/identidades/oa/desktop/12.jpg' */
import img13 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero13.jpg'
import img14 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero14.jpg'
import img15 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero15.jpg'
import img16 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero16.jpg'
import img17 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero17.jpg'
import img18 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero18.jpg'
import img19 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero19.jpg'
import img20 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero20.jpg'
import img21 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero21.jpg'
import img22 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero22.jpg'
import img23 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero23.jpg'
import img24 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero24.jpg'
import img25 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero25.jpg'
import img26 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero26.jpg'
import img27 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero27.jpg'
import img28 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero28.jpg'
import img29 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero29.jpg'
import img30 from '../../images/identidades/oa/desktop/branding-oa-comunicacao-digital-criacao-identidade-visual-numero30.jpg'

function OA() {
    return (
        <div className="presentation">
            <img src={img1} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img2} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img3} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img4} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img5} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img6} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img7} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img8} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img9} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img10} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img11} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
         {/*    <img src={img12} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" /> */}
            <img src={img13} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img14} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img15} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img16} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img17} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img18} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img19} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img20} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img21} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img22} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img23} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img24} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img25} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img26} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img27} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img28} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img29} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
            <img src={img30} alt="Branding da OA Comunicação Digital com Identidade Visual Completa" />
        </div>
    );
}

export default OA;