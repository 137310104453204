import Bpage6 from "../../components/blog-pages/Bpage6";
import Header from "../../components/Header";
import Sobre from "../../components/Sobre";

function Page6() {
    return (
        <div>
            <Header isComunicacao={false}></Header>
            <Bpage6></Bpage6>
            <Sobre></Sobre>
        </div>
    )

}

export default Page6;