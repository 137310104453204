import "./Projetos.scss"
import { Link } from 'react-router-dom'


function Projetos() {
    return (
        <div id="projetos">
            <div className="ident">
                <h1>Identidades Visuais</h1>

                <div className="wrap-projetos">
                    <Link className="nav-link" to="/identidade-visual-mabu-aviamentos-comunicacao">
                        <div className="proj mabu">
                            <h2>Mabu Aviamentos</h2>
                        </div>
                    </Link>
                    <Link className="nav-link" to="/identidade-visual-ddvr-elevadores-comunicacao">
                        <div className="proj dvr">
                            <h2>DVR Elevadores</h2>
                        </div>
                    </Link>
                    <Link className="nav-link" to="/identidade-visual-selaria-mg-comunicacao">
                        <div className="proj selaria">
                            <h2>Selaria</h2>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}


export default Projetos;