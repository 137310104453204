import "./Bpages.scss"
import NavPage from "./NavPages";

function Bpage6() {
    return (
        <div id="bpage">
            <div className="indent">
                <h1>Criação de Fantasias e Mascotes: Conectando Marcas e Pessoas</h1>
                <h2>Fantasias e Mascotes que Transformam sua Marca</h2>

                <p>Mascotes e fantasias personalizadas criam uma conexão emocional com o público, humanizando sua marca e tornando-a inesquecível.</p>

                <p><span>Por Que Investir em Mascotes?:</span> Eles aumentam o engajamento, criam identificação e fortalecem a presença da marca em eventos e campanhas.</p>

                <p><span>Fantasias Personalizadas:</span> Desenvolvemos fantasias para eventos promocionais, campanhas de marketing e ações especiais, com foco na criatividade e na qualidade.</p>

                <p><span>Diferenciais da Nossa Criação:</span> Trabalhamos com materiais de alta qualidade, designs inovadores e atenção aos detalhes para garantir o impacto visual.</p>

                <p><span>Call-to-Action:</span> Surpreenda seu público com fantasias e mascotes que encantam. Converse com nossos especialistas!</p>
            </div>
            <NavPage hide={'5'}></NavPage>
        </div>
    )
}

export default Bpage6;