import React from "react";
import Carousel from "react-bootstrap/Carousel";
import banner from "../images/banner-criacao-mascote3d-varias-poses.jpg";
import bannerMobile from "../images/banner-criacao-mascote3d-varias-poses-mobile.jpg";
import banner2 from "../images/banner-criacao-mascote-modelagem3d.jpg";
import banner2Mobile from "../images/banner-criacao-mascote-modelagem3d-mobile.jpg";
import banner3 from "../images/banner-criacao-mascote-modelagem3d360.jpg";
import banner3Mobile from "../images/banner-criacao-mascote-modelagem3d360-mobile.jpg";
import banner4 from "../images/banner-criacao-logo.jpg";
import banner4Mobile from "../images/banner-criacao-logo-mobile.jpg";

import "./Carousel.scss"

function CarouselBootstrap() {
  return (
    <div id="banners">
      <Carousel prevIcon={false} nextIcon={false}>
      <Carousel.Item>
          <a href="https://mascotetop.com.br/" target="_blank" rel="noreferrer" title="Acesse nosso site com mascotes exclusivos e personalizados.">
            <picture>
              <source media="(max-width: 800px)" srcSet={banner2Mobile} alt="Banner Modelagem 3D." />
              <img className="d-block w-100" src={banner2} alt="Banner Modelagem 3D." />
            </picture>
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <a href="https://mascotetop.com.br/" target="_blank" rel="noreferrer" title="Acesse nosso site com mascotes exclusivos e personalizados.">
            <picture>
              <source media="(max-width: 800px)" srcSet={bannerMobile} alt="Macote 3D em varias poses" />
              <img className="d-block w-100" src={banner} alt="Macote 3D em varias poses." />
            </picture>
            {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
          </a>
        </Carousel.Item>
        <Carousel.Item>
          <picture>
            <source media="(max-width: 800px)" srcSet={banner4Mobile} alt="Banner de Criação de logo 2D." />
            <img className="d-block w-100" src={banner4} alt="Banner de Criação de logo 2D." />
          </picture>
          {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <a href="https://mascotetop.com.br/" target="_blank" rel="noreferrer" title="Acesse nosso site com mascotes exclusivos e personalizados.">
            <picture>
              <source media="(max-width: 800px)" srcSet={banner3Mobile} alt="Banner de Modelagem 3D 360 na criação de mascotes." />
              <img className="d-block w-100" src={banner3} alt="Banner de Modelagem 3D 360 na criação de mascotes." />
            </picture>
            {/* <Carousel.Caption>
          <h3>First slide label</h3>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
        </Carousel.Caption> */}
          </a>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default CarouselBootstrap;
