//import Identidade from "../components/Identidade";

import { useParams } from 'react-router-dom';
//import { Oaid } from '../components/Oaid';
import OA from '../components/identidades/OA';
import Estrela from '../components/identidades/Estrela';
import Selaria from '../components/identidades/Selaria';
import Xavier from '../components/identidades/Xavier';
import Mabu from '../components/identidades/Mabu';
import Staff from '../components/identidades/Staff';


function IdentidadeVisual() {
    window.scrollTo(0, 0);
    const { id } = useParams()

    return (
        <div>
            {id === 'oa' ? <OA /> : null}
            {id === 'estrela' ? <Estrela /> : null}
            {id === 'xavier' ? <Xavier /> : null}
            {id === 'selaria' ? <Selaria /> : null}
            {id === 'staff' ? <Staff /> : null}
            {id === 'mabu' ? <Mabu /> : null}
        </div>
    );
}


export default IdentidadeVisual;