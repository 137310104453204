import React from "react";
import './identidades.scss'
import img1 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero1.jpg'
import img2 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero2.jpg'
import img3 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero3.jpg'
import img4 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero4.jpg'
import img5 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero5.jpg'
import img6 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero6.jpg'
import img7 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero7.jpg'
import img8 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero8.jpg'
import img9 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero9.jpg'
import img10 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero10.jpg'
import img11 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero11.jpg'
import img12 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero12.jpg'
import img13 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero13.jpg'
import img14 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero14.jpg'
import img15 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero15.jpg'
import img16 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero16.jpg'
import img17 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero17.jpg'
import img18 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero18.jpg'
import img19 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero19.jpg'
import img20 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero20.jpg'
import img21 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero21.jpg'
import img22 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero22.jpg'
import img23 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero23.jpg'
import img24 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero24.jpg'
import img25 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero25.jpg'
import img26 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero26.jpg'
import img27 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero27.jpg'
import img28 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero28.jpg'
import img29 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero29.jpg'
import img30 from '../../images/identidades/staff/desktop/branding-criacao-identidade-visual-completa-staff-brasil-numero30.jpg'


function Staff() {
    return (
        <div className="presentation">
            <img src={img1} alt="Logo da Staff do Brasil" />
            <img src={img2} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img3} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img4} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img5} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img6} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img7} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img8} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img9} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img10} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img11} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img12} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img13} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img14} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img15} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img16} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img17} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img18} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img19} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img20} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img21} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img22} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img23} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img24} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img25} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img26} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img27} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img28} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img29} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
            <img src={img30} alt="Branding e Criação de Identidade Visual Completa da Staff do Brasil" />
        </div>
    );
}

export default Staff;