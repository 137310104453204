import "./Bpages.scss"
import NavPage from "./NavPages";

function Bpage4() {
    return (
        <div id="bpage">
            <div className="indent">
                <h1>Criação de Web Design: Experiência Digital que Converte</h1>
                <h2>Web Design que Alinha Beleza e Funcionalidade</h2>

                <p>O design do site é um dos fatores mais importantes para atrair e reter visitantes. Oferecemos soluções que combinam estética e funcionalidade.</p>

                <p><span>Design Responsivo e Otimizado:</span> Criamos sites que se adaptam a todos os dispositivos, oferecendo uma experiência impecável para os usuários.</p>

                <p><span>Foco em Experiência do Usuário (UX):</span> Garantimos navegação intuitiva e rápida, aumentando a conversão de visitantes para clientes.</p>

                <p><span>Tendências em Web Design:</span> Microinterações, minimalismo e animações sutis estão entre as estratégias que utilizamos.</p>

                <p><span>Call-to-Action:</span> Quer um site que destaque sua marca? Solicite uma consultoria conosco!</p>
            </div>
            <NavPage hide={'4'}></NavPage>
        </div>
    )
}

export default Bpage4;