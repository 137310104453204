import React from "react";
import ImagePort from "./ImagePort";
import "./Logos.scss";



import logo1 from "../images/logos/criacao-logo-mabu-aviamentos.jpg";
import logo2 from "../images/logos/criacao-logo-selaria-mg.jpg";
import logo3 from "../images/logos/criacao-logo-xavier-e-melo.jpg";
import logo4 from "../images/logos/criacao-logo-tiny-pop.jpg";
import logo5 from "../images/logos/criacao-logo-credfacil-ceres.jpg";
import logo6 from "../images/logos/criacao-logo-mascote-posto-do-vale.jpg";
import logo7 from "../images/logos/criacao-logo3d-lups-esportes.jpg";
import logo8 from "../images/logos/criacao-logo-acai-dom-premium.jpg";
import logo9 from "../images/logos/criacao-logo-baratao-distribuidora.jpg";
import logo10 from "../images/logos/criacao-logo-atletica-monstruosa.jpg";
import logo11 from "../images/logos/criacao-logo-xinellus.jpg";
import logo12 from "../images/logos/criacao-logo-sr-cafezin.jpg";
import logo13 from "../images/logos/criacao-logo-estrela-do-ceu.jpg";
import logo14 from "../images/logos/criacao-logo-ddvr-elevadores.jpg";
import logo15 from "../images/logos/criacao-logo-benac-kids.jpg";
import logo16 from "../images/logos/logo-criado-mascote-top.jpg";


function Logos() {
    return (
        <div id="logos">
            <h1>Logos Criados</h1>
            <div className="container">
                <div className="top">
                    <ImagePort image={logo1} number={1} altName={'Logo 2D criado para Mabu Aviamentos'}></ImagePort>
                    <ImagePort image={logo2} number={2} altName={'Logo 2D criado para Selaria'}></ImagePort>
                    <ImagePort image={logo3} number={3} altName={'Logo 2D criado para Xavier & Melo'}></ImagePort>
                    <ImagePort image={logo4} number={4} altName={'Logo 3D criado para Tiny Pop'}></ImagePort>
                    <ImagePort image={logo5} number={5} altName={'Logo 2D criado para Cred Fácil Ceres'}></ImagePort>
                    <ImagePort image={logo6} number={6} altName={'Logo 3D criado para Posto do Vale'}></ImagePort>
                    <ImagePort image={logo7} number={7} altName={'Logo 3D criado para Lups Esportes Kids e Teens'}></ImagePort>
                    <ImagePort image={logo8} number={8} altName={'Logo 3D criado para Açai Dom Premium'}></ImagePort>
                    <ImagePort image={logo9} number={9} altName={'Logo 3D criado para Baratão Distribuidora de moto peças'}></ImagePort>
                    <ImagePort image={logo10} number={10} altName={'Logo 3D criado para UDF Monstruosa'}></ImagePort>
                    <ImagePort image={logo11} number={11} altName={'Logo 3D criado para Xinellus'}></ImagePort>
                    <ImagePort image={logo12} number={12} altName={'Logo 2D criado para SR Cafezin'}></ImagePort>
                    <ImagePort image={logo13} number={13} altName={'Logo 3D criado para Estrela do ceu'}></ImagePort>
                    <ImagePort image={logo14} number={14} altName={'Logo 3D criado para DVR Elevadores'}></ImagePort>
                    <ImagePort image={logo15} number={15} altName={'Logo criado em 3D para Espaço Benac Kids'}></ImagePort>
                    <ImagePort image={logo16} number={16} altName={'Logo criado em 3D para Mascote Top'}></ImagePort>
                </div>
            </div>
        </div>
    );
}

export default Logos;