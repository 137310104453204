import "./Solucoes.scss";
import { Link } from 'react-router-dom'
import imgPattern from "../images/solucoes_pattern.png"
import imgSolucoes1 from "../images/solucoes/solucoes1.jpg"
import imgSolucoes2 from "../images/solucoes/solucoes2.jpg"
import imgSolucoes3 from "../images/solucoes/solucoes3.jpg"
import imgSolucoes4 from "../images/solucoes/solucoes4.jpg"
import imgSolucoes5 from "../images/solucoes/solucoes5.jpg"
import imgSolucoes7 from "../images/solucoes/solucoes7.jpg"
import imgSolucoes8 from "../images/solucoes/solucoes8.jpg"
import imgSolucoes9 from "../images/solucoes/solucoes9.jpg"


function Solucoes() {
    let mobile = window.screen.width

    function scrollToTop() {
        window.scrollTo(0, 0)
    }


    function toggleMenu(number) {
        if (number === 'btn_mobile') {
            document.getElementById('mobile_shadow').classList.contains('active') ?
                hideContent() :
                document.getElementById('mobile_shadow').classList.add('active');
        } else {
            hideContent();
            document.getElementById('mobile_shadow').classList.add('active');
            const selectedMenu = 'menuS' + number;
            document.getElementById(selectedMenu).classList.add('active')
            const elementImage = 'ims' + number;
            document.getElementById(elementImage).classList.add('active')
        }

        function hideContent() {
            document.getElementById('mobile_shadow').classList.remove('active')
            document.getElementById('menuS1').classList.remove('active')
            document.getElementById('menuS2').classList.remove('active')
            document.getElementById('menuS3').classList.remove('active')
            document.getElementById('menuS4').classList.remove('active')
            document.getElementById('menuS5').classList.remove('active')
            document.getElementById('menuS6').classList.remove('active')
            document.getElementById('menuS7').classList.remove('active')
            document.getElementById('menuS8').classList.remove('active')
            document.getElementById('menuS9').classList.remove('active')
            document.getElementById('ims1').classList.remove('active')
            document.getElementById('ims2').classList.remove('active')
            document.getElementById('ims3').classList.remove('active')
            document.getElementById('ims4').classList.remove('active')
            document.getElementById('ims5').classList.remove('active')
            document.getElementById('ims6').classList.remove('active')
            document.getElementById('ims7').classList.remove('active')
            document.getElementById('ims8').classList.remove('active')
            document.getElementById('ims9').classList.remove('active')
        }
    }

    return (
        <div id="solucoes">
            <div className="menusolucoes">
                <div className="solucao">
                    <div className="titulo">
                        <h2>Soluções</h2>
                    </div>
                    <nav>
                        <ul>
                            <li id="menuS1" className={"menuS" + (mobile > 800 ? ' active' : '')} onClick={(() => toggleMenu('1'))}>Identidade Visual</li>
                            <li id="menuS2" className="menuS" onClick={(() => toggleMenu('2'))}>Criação de Logos</li>
                            <li id="menuS3" className="menuS" onClick={(() => toggleMenu('3'))}>Criação de Mascotes</li>
                            <li id="menuS4" className="menuS" onClick={(() => toggleMenu('4'))}>Modelagem 3D</li>
                            <li id="menuS5" className="menuS" onClick={(() => toggleMenu('5'))}>Ilustração Digital</li>
                            <li id="menuS6" className="menuS" onClick={(() => toggleMenu('6'))}>Vídeos - Motion Design</li>
                            <li id="menuS7" className="menuS" onClick={(() => toggleMenu('7'))}>Social Media</li>
                            <li id="menuS8" className="menuS" onClick={(() => toggleMenu('8'))}>Web Design</li>
                            <li id="menuS9" className="menuS" onClick={(() => toggleMenu('9'))}>Fantasias</li>
                        </ul>
                    </nav>
                </div>
                <div className="images">
                    <div className="titulo">
                        <img className="pattern" src={imgPattern} alt="pattern OA"></img>
                    </div>
                    <div className="wrap-box">
                        <Link className="logo nav-link" to="/criacao-de-identidades-visuais" title="link para página de criação de Identidades Visuais" onClick={scrollToTop}>
                            <img id="ims1" className={mobile > 800 ? 'active' : ''} src={imgSolucoes1} alt="Soluções 1"></img>
                        </Link>
                        <Link className="logo nav-link" to="/criacao-de-logos" title="link para página de criação de logos" onClick={scrollToTop}>
                            <img id="ims2" src={imgSolucoes2} alt="Soluções 2"></img>
                        </Link>
                        <a href="https://mascotetop.com.br/" target="_blank" rel="noreferrer" title="Acesse nosso site com mascotes exclusivos e personalizados.">
                            <img id="ims3" src={imgSolucoes3} alt="Soluções 3"></img>
                        </a>
                        <img id="ims4" src={imgSolucoes4} alt="Soluções 4"></img>
                        <img id="ims5" src={imgSolucoes5} alt="Soluções 6"></img>
                        <iframe id="ims6" width="100%" height="500" src="https://www.youtube.com/embed/4SxQ8cLT8vY?si=MR_fIER1F4Z8HIs1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        <img id="ims7" src={imgSolucoes7} alt="Soluções 7"></img>
                        <img id="ims8" src={imgSolucoes8} alt="Soluções 8"></img>
                        <a href="https://mascotetop.com.br/fantasias" target="_blank" rel="noreferrer" title="Acesse nosso site com mascotes e fantasias exclusivas.">
                            <img id="ims9" src={imgSolucoes9} alt="Soluções 9"></img>
                        </a>
                        <div id="mobile_shadow" onClick={(() => toggleMenu('btn_mobile'))}></div>
                    </div>
                </div>
            </div>
        </div>
    )



}


export default Solucoes;