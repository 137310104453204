import React from "react";
import './identidades.scss'
import img1 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero1.jpg'
import img2 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero2.jpg'
import img3 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero3.jpg'
import img4 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero4.jpg'
import img5 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero5.jpg'
import img6 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero6.jpg'
import img7 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero7.jpg'
import img8 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero8.jpg'
import img9 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero9.jpg'
import img10 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero10.jpg'
import img11 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero11.jpg'
import img12 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero12.jpg'
import img13 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero13.jpg'
import img14 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero14.jpg'
import img15 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero15.jpg'
import img16 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero16.jpg'
import img17 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero17.jpg'
import img18 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero18.jpg'
import img19 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero19.jpg'
import img20 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero20.jpg'
import img21 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero21.jpg'
import img22 from '../../images/identidades/xavier/desktop/criacao-de-identidade-visual-logo-xavier-&-melo-numero22.jpg'


function Xavier() {
    return (
        <div className="presentation">
            <img src={img1} alt="Logo criado para Xavier & Melo" />
            <img src={img2} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img3} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img4} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img5} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img6} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img7} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img8} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img9} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img10} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img11} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img12} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img13} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img14} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img15} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img16} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img17} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img18} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img19} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img20} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img21} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
            <img src={img22} alt="Criação de Logo e Identidade Visual Completa para Xavier & Melo Identidade Visual" />
        </div>
    );
}

export default Xavier;