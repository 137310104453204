import { Link } from "react-router-dom";
import Header from "../components/Header";

function ErrorPage() {
    return <div id="errorpage">
        <Header />
        <br />
        <br />
        <br />
        <h1>Página não encontrada</h1>
        <br />
        <br />
        <p>Clique para voltar a tela inicial <Link to="/">Página inicial (Home)</Link></p>
        <br />
    </div>
}

export default ErrorPage;