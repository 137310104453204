import React from "react";
import './identidades.scss'
import img1 from '../../images/identidades/selaria/desktop/criacao-identidade-visual-completa-selaria-mg-brasil-n1.jpg'
import img2 from '../../images/identidades/selaria/desktop/criacao-identidade-visual-completa-selaria-mg-brasil-n2.jpg'
import img3 from '../../images/identidades/selaria/desktop/criacao-identidade-visual-completa-selaria-mg-brasil-n3.jpg'
import img4 from '../../images/identidades/selaria/desktop/criacao-identidade-visual-completa-selaria-mg-brasil-n4.jpg'
import img5 from '../../images/identidades/selaria/desktop/criacao-identidade-visual-completa-selaria-mg-brasil-n5.jpg'
import img6 from '../../images/identidades/selaria/desktop/criacao-identidade-visual-completa-selaria-mg-brasil-n6.jpg'
import img7 from '../../images/identidades/selaria/desktop/criacao-identidade-visual-completa-selaria-mg-brasil-n7.jpg'
import img8 from '../../images/identidades/selaria/desktop/criacao-identidade-visual-completa-selaria-mg-brasil-n8.jpg'

function Selaria() {
    return (
        <div className="presentation">
            <img src={img1} alt="Branding com Criação de Identidade Visual Completa para Selaria MG Brasil" />
            <img src={img2} alt="Branding com Criação de Identidade Visual Completa para Selaria MG Brasil" />
            <img src={img3} alt="Branding com Criação de Identidade Visual Completa para Selaria MG Brasil" />
            <img src={img4} alt="Branding com Criação de Identidade Visual Completa para Selaria MG Brasil" />
            <img src={img5} alt="Branding com Criação de Identidade Visual Completa para Selaria MG Brasil" />
            <img src={img6} alt="Branding com Criação de Identidade Visual Completa para Selaria MG Brasil" />
            <img src={img7} alt="Branding com Criação de Identidade Visual Completa para Selaria MG Brasil" />
            <img src={img8} alt="Branding com Criação de Identidade Visual Completa para Selaria MG Brasil" />
        </div>
    );
}

export default Selaria;