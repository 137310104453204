import "./Bpages.scss"
import NavPage from "./NavPages";

function Bpage2() {
    return (
        <div id="bpage">
            <div className="indent">
                <h1>Criação de Identidade Visual: Um Alinhamento Estratégico</h1>
                <h2>Identidade Visual: Mais do que Estética, uma Estratégia de Marca</h2>

                <p>A identidade visual é a linguagem visual da sua marca. Ela vai além do logotipo, incluindo paleta de cores, tipografia, estilo gráfico e mais.</p>

                <p><span> O Que Incluímos na Identidade Visual:</span> Desenvolvemos paletas de cores únicas, tipografias e elementos gráficos consistentes que tornam sua marca reconhecível.</p>

                <p><span>Como uma Boa Identidade Visual Aumenta as Vendas:</span> Marcas com identidade visual coesa transmitem profissionalismo, fortalecendo a confiança e o desejo do consumidor.</p>

                <p><span>Nosso Diferencial:</span> Unimos criatividade e estratégia para garantir uma identidade visual que se destaque e seja funcional em diferentes plataformas.</p>

                <p><span>Call-to-Action:</span>s Precisando de uma identidade visual para fortalecer sua marca? Fale conosco!</p>
            </div>
            <NavPage hide={'2'}></NavPage>
        </div>
    )
}

export default Bpage2;