import "./Bpages.scss"
import NavPage from "./NavPages";

function Bpage5() {
    return (
        <div id="bpage">
            <div className="indent">
                <h1>Criação de Sites: A Base para sua Presença Online</h1>
                <h2>Por Que Investir em um Site Profissional é Essencial?</h2>

                <p>Seu site é o centro da sua presença digital. Ele deve ser funcional, atrativo e otimizado para mecanismos de busca.</p>

                <p><span>SEO como Prioridade:</span> Otimizamos cada página para garantir que seu site apareça nos primeiros resultados do Google.</p>

                <p><span>Funcionalidade e Velocidade:</span> Nossos sites são rápidos, seguros e fáceis de usar, oferecendo a melhor experiência ao usuário.</p>

                <p><span>Soluções Personalizadas:</span> Criamos sites sob medida para atender às necessidades do seu negócio e atrair mais clientes.</p>

                <p><span>Call-to-Action:</span> Pronto para alavancar sua presença online? Fale com nossa equipe!</p>
            </div>
            <NavPage hide={'5'}></NavPage>
        </div>
    )
}

export default Bpage5;