import "./Bpages.scss"
import NavPage from "./NavPages";

function Bpage1() {
    return (
        <div id="bpage">
            <div className="indent">
                <h1>Criação de Logos: Construindo a Identidade da Sua Marca</h1>
                <h2>Por Que o Logotipo é o Primeiro Passo para o Sucesso da Sua Marca?</h2>

                <p>Um logotipo bem desenvolvido é a base para uma marca forte e memorável. Ele comunica a essência do seu negócio, atrai o público certo e gera confiança.</p>

                <p><span>A Importância do Logotipo:</span> Um bom logotipo é simples, versátil e alinhado aos valores da sua marca. Ele é responsável por causar a primeira impressão e fortalecer sua identidade no mercado.</p>

                <p><span>Nosso Processo de Criação:</span> Trabalhamos com pesquisas, brainstorming criativo e refinamento para criar um logotipo que seja único e marcante.</p>

                <p><span>Tendências em Design de Logos:</span> Minimalismo, cores gradientes e elementos geométricos são tendências que dominam o mercado.</p>

                <p><span>Call-to-Action:</span> Invista em um logotipo profissional que represente sua marca e faça a diferença. Entre em contato conosco!</p>

            </div>
            <NavPage hide={'1'}></NavPage>
        </div>
    )
}

export default Bpage1;