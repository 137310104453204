import Header from "../components/Header";
import Logos from "../components/Logos";


function LogoPage() {
    return (
        <div>
            <Header isComunicacao={true}></Header>
            <Logos></Logos>
        </div>
    );
}

export default LogoPage;