import React from "react";
import './identidades.scss'
import img1 from '../../images/identidades/estrela/desktop/criacao-identidade-visual-estrela-do-ceu-n1.jpg'
import img2 from '../../images/identidades/estrela/desktop/criacao-identidade-visual-estrela-do-ceu-n2.jpg'
import img3 from '../../images/identidades/estrela/desktop/criacao-identidade-visual-estrela-do-ceu-n3.jpg'
import img4 from '../../images/identidades/estrela/desktop/criacao-identidade-visual-estrela-do-ceu-n4.jpg'
import img5 from '../../images/identidades/estrela/desktop/criacao-identidade-visual-estrela-do-ceu-n5.jpg'
import img6 from '../../images/identidades/estrela/desktop/criacao-identidade-visual-estrela-do-ceu-n6.jpg'
import img7 from '../../images/identidades/estrela/desktop/criacao-identidade-visual-estrela-do-ceu-n7.jpg'
import img8 from '../../images/identidades/estrela/desktop/criacao-identidade-visual-estrela-do-ceu-n8.jpg'
import img9 from '../../images/identidades/estrela/desktop/criacao-identidade-visual-estrela-do-ceu-n9.jpg'
import img10 from '../../images/identidades/estrela/desktop/criacao-identidade-visual-estrela-do-ceu-n10.jpg'
import img11 from '../../images/identidades/estrela/desktop/criacao-identidade-visual-estrela-do-ceu-n11.jpg'
import img12 from '../../images/identidades/estrela/desktop/criacao-identidade-visual-estrela-do-ceu-n12.jpg'
import img13 from '../../images/identidades/estrela/desktop/criacao-identidade-visual-estrela-do-ceu-n13.jpg'
import img14 from '../../images/identidades/estrela/desktop/criacao-identidade-visual-estrela-do-ceu-n14.jpg'
import img15 from '../../images/identidades/estrela/desktop/criacao-identidade-visual-estrela-do-ceu-n15.jpg'
import img16 from '../../images/identidades/estrela/desktop/criacao-identidade-visual-estrela-do-ceu-n16.jpg'

function Estrela() {
    return (
        <div className="presentation">
            <img src={img1} alt="Criação de identidade Visual e Logo da Estrela do céu" />
            <img src={img2} alt="Criação de identidade Visual da Estrela do céu" />
            <img src={img3} alt="Criação de identidade Visual da Estrela do céu" />
            <img src={img4} alt="Criação de identidade Visual da Estrela do céu" />
            <img src={img5} alt="Criação de identidade Visual da Estrela do céu" />
            <img src={img6} alt="Criação de identidade Visual da Estrela do céu" />
            <img src={img7} alt="Criação de identidade Visual da Estrela do céu" />
            <img src={img8} alt="Criação de identidade Visual da Estrela do céu" />
            <img src={img9} alt="Criação de identidade Visual da Estrela do céu" />
            <img src={img10} alt="Criação de identidade Visual da Estrela do céu" />
            <img src={img11} alt="Criação de identidade Visual da Estrela do céu" />
            <img src={img12} alt="Criação de identidade Visual da Estrela do céu" />
            <img src={img13} alt="Criação de identidade Visual da Estrela do céu" />
            <img src={img14} alt="Criação de identidade Visual da Estrela do céu" />
            <img src={img15} alt="Criação de identidade Visual da Estrela do céu" />
            <img src={img16} alt="Criação de identidade Visual da Estrela do céu" />
        </div>
    );
}

export default Estrela;