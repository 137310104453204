import React from "react";
import './identidades.scss'
import img1 from '../../images/identidades/ddvr/desktop/ddvr-elevadores-identidade-visual-n1.jpg'
import img2 from '../../images/identidades/ddvr/desktop/ddvr-elevadores-identidade-visual-n2.jpg'
import img3 from '../../images/identidades/ddvr/desktop/ddvr-elevadores-identidade-visual-n3.jpg'
import img4 from '../../images/identidades/ddvr/desktop/ddvr-elevadores-identidade-visual-n4.jpg'
import img5 from '../../images/identidades/ddvr/desktop/ddvr-elevadores-identidade-visual-n5.jpg'
import img6 from '../../images/identidades/ddvr/desktop/ddvr-elevadores-identidade-visual-n6.jpg'
import img7 from '../../images/identidades/ddvr/desktop/ddvr-elevadores-identidade-visual-n7.jpg'
import img8 from '../../images/identidades/ddvr/desktop/ddvr-elevadores-identidade-visual-n8.jpg'
import img9 from '../../images/identidades/ddvr/desktop/ddvr-elevadores-identidade-visual-n9.jpg'
import img10 from '../../images/identidades/ddvr/desktop/ddvr-elevadores-identidade-visual-n10.jpg'
import img11 from '../../images/identidades/ddvr/desktop/ddvr-elevadores-identidade-visual-n11.jpg'
import img12 from '../../images/identidades/ddvr/desktop/ddvr-elevadores-identidade-visual-n12.jpg'
import img13 from '../../images/identidades/ddvr/desktop/ddvr-elevadores-identidade-visual-n13.jpg'
import img14 from '../../images/identidades/ddvr/desktop/ddvr-elevadores-identidade-visual-n14.jpg'
import img15 from '../../images/identidades/ddvr/desktop/ddvr-elevadores-identidade-visual-n15.jpg'

function Dvr() {
    return (
        <div className="presentation">
            <img src={img1} alt="Ddvr Elevadores Logotipo" />
            <img src={img2} alt="Ddvr Elevadores Identidade Visual" />
            <img src={img3} alt="Ddvr Elevadores Identidade Visual" />
            <img src={img4} alt="Ddvr Elevadores Identidade Visual" />
            <img src={img5} alt="Ddvr Elevadores Identidade Visual" />
            <img src={img6} alt="Ddvr Elevadores Identidade Visual" />
            <img src={img7} alt="Ddvr Elevadores Identidade Visual" />
            <img src={img8} alt="Ddvr Elevadores Identidade Visual" />
            <img src={img9} alt="Ddvr Elevadores Identidade Visual" />
            <img src={img10} alt="Ddvr Elevadores Identidade Visual" />
            <img src={img11} alt="Ddvr Elevadores Identidade Visual" />
            <img src={img12} alt="Ddvr Elevadores Identidade Visual" />
            <img src={img13} alt="Ddvr Elevadores Identidade Visual" />
            <img src={img14} alt="Ddvr Elevadores Identidade Visual" />
            <img src={img15} alt="Ddvr Elevadores Identidade Visual" />
        </div>
    );
}

export default Dvr;