import Bpage1 from "../../components/blog-pages/Bpage1";
import Header from "../../components/Header";
import Sobre from "../../components/Sobre";


function Page1() {
    return (
        <div>
            <Header isComunicacao={false}></Header>
            <Bpage1></Bpage1>
            <Sobre></Sobre>
        </div>
    )

}

export default Page1;