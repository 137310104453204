import './App.scss';
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/js/src/carousel.js"
import "bootstrap/js/src/util/index.js"
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import HomePage from './pages/Home';
import OAPage from './pages/comunicacao/OA';
import RootLayout from './pages/Root';
import ErrorPage from './pages/Error';
import IdentidadeVisual from './pages/Identidade-visual';
import EstrelaPage from './pages/comunicacao/Estrela';
import XavierPage from './pages/comunicacao/Xavier';
import SelariaPage from './pages/comunicacao/Selaria';
import StaffPage from './pages/comunicacao/Staff';
import MabuPage from './pages/comunicacao/Mabu';
import ProjetosPage from './pages/Projetos';
import LogoPage from './pages/LogoPage';
import DdvrPage from './pages/comunicacao/DdvrPage';
import Page1 from './pages/blog/Page1';
import Page2 from './pages/blog/Page2';
import Page3 from './pages/blog/Page3';
import Page4 from './pages/blog/Page4';
import Page5 from './pages/blog/Page5';
import Page6 from './pages/blog/Page6';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorPage/>,
    children: [
      { path: '/', element: <HomePage /> },
      { path: '/criacao-de-identidades-visuais', element: <ProjetosPage /> },
      { path: '/identidade-visual-oa-comunicacao-digital', element: <OAPage /> },
      { path: '/identidade-visual-estrela-do-ceu-comunicacao', element: <EstrelaPage /> },
      { path: '/identidade-visual-xavier-e-melo-comunicacao', element: <XavierPage /> },
      { path: '/identidade-visual-selaria-mg-comunicacao', element: <SelariaPage /> },
      { path: '/identidade-visual-staff-do-brasil-comunicacao', element: <StaffPage /> },
      { path: '/identidade-visual-mabu-aviamentos-comunicacao', element: <MabuPage /> },
      { path: '/identidade-visual-ddvr-elevadores-comunicacao', element: <DdvrPage /> },
      { path: '/criacao-de-logos', element: <LogoPage /> },
      { path: '/identidade-visual/:id', element: <IdentidadeVisual /> },
      { path: '/blog', element: <Page1 /> },
      { path: '/criacao-de-logos-profissionais', element: <Page1 /> },
      { path: '/criacao-de-identidade-visual', element: <Page2 /> },
      { path: '/videos-motion-graphics-dinamicos', element: <Page3 /> },
      { path: '/web-design-experiencia-digital', element: <Page4 /> },
      { path: '/criacao-de-sites-profissionais', element: <Page5 /> },
      { path: '/criacao-de-fantasias-personalizadas', element: <Page6 /> },
    ],
  },
]);

function App() {
  return (
    <div className="App">
      {/* <Header></Header>
      <CarouselBootstrap></CarouselBootstrap>
      <Portifolio></Portifolio>
      <Logos></Logos>
      <Sobre></Sobre>
      <Servicos></Servicos>
      <Footer></Footer> */}
      <RouterProvider router={router}></RouterProvider>
    </div>
  );
}

export default App;
