import React from "react";
import './ImagePort.scss'
import searchIcon from "../images/pesquisa.png";

function ImagePort(props) {
    const callImg = () => {
        document.getElementById(props.number).classList.contains('show') ?
        document.getElementById(props.number).classList.remove('show') :
        document.getElementById(props.number).classList.add('show');
    }

    return (
        <div className="block">
            <ul>
                <li onClick={callImg} id="image" ><img src={props.image} alt={props.altName}/>
                <span className="searchIcon">
                        <img src={searchIcon} alt="Ícone de pesquisa" />
                    </span>
                </li>
            </ul>
            <div onClick={callImg} className="lightbox trans" id={props.number}>
                <img src={props.image} alt={props.altName} />
            </div>
        </div>
    );

}

export default ImagePort;