import Header from '../../components/Header'
import Mabu from '../../components/identidades/Mabu';

function MabuPage() {
    window.scrollTo(0, 0);
    return (
        <div>
            <Header isComunicacao={true}></Header>
            <Mabu />
        </div>
    )
}

export default MabuPage;