import "./Identidade.scss"
import { Link } from 'react-router-dom'
import idVisual1 from "../images/identidade-visual-oa-comunicacao-digital.jpg"
import idVisual2 from "../images/criacao-de-identidade-visual-estrela-do-ceu.jpg"
import idVisual3 from "../images/criacao-de-identidade-visual-xavier&melo.jpg"
import idVisual5 from "../images/criacao-de-identidade-visual-staff.jpg"
import searchIcon from "../images/pesquisa.png";
import arrow from "../images/arrow.svg"



function Identidade() {
    return (
        <div id="identidade">
            <div className="identidade_visual">
                <div className="wrap box1">
                    <h1>Nossos Projetos</h1>
                    <li className="nav-item">
                        <Link className="nav-link" to="/identidade-visual-oa-comunicacao-digital">
                            <img src={idVisual1} alt="Criação de identidade Visual da OA Comunicação Digital"></img>
                            <span className="searchIcon">
                                <img src={searchIcon} alt="Ícone de pesquisa" />
                            </span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/identidade-visual-estrela-do-ceu-comunicacao">
                            <img src={idVisual2} alt="Criação de identidade Visual da OA Estrela do Céu"></img>
                            <span className="searchIcon">
                                <img src={searchIcon} alt="Ícone de pesquisa" />
                            </span>
                        </Link>
                    </li>
                </div>
                <div className="wrap box2">
                    <li className="nav-item">
                        <Link className="nav-link" to="/identidade-visual-xavier-e-melo-comunicacao">
                            <img src={idVisual3} alt="Criação de identidade Visual da Xavier & Melo"></img>
                            <span className="searchIcon">
                                <img src={searchIcon} alt="Ícone de pesquisa" />
                            </span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/identidade-visual-staff-do-brasil-comunicacao">
                            <img src={idVisual5} alt="Criação identidade Visual da Staff"></img>
                            <span className="searchIcon">
                                <img src={searchIcon} alt="Ícone de pesquisa" />
                            </span>
                        </Link>
                    </li>
                    <a className="btn-mais" href="/criacao-de-identidades-visuais">Veja mais<img className="arrow" src={arrow} alt="Seta"></img> </a>
                </div>
            </div>
        </div>
    );
}


export default Identidade;

