import "./Bpages.scss"
import NavPage from "./NavPages";

function Bpage3() {
    return (
        <div id="bpage">
            <div className="indent">
                <h1>Criação de Vídeos Motion Graphics: Conteúdo que Conquista</h1>
                <h2>Motion Graphics: A Ferramenta Perfeita para Engajar seu Público</h2>

                <p>Os vídeos em motion graphics são uma forma dinâmica de contar histórias, apresentar produtos ou educar o público de maneira criativa.</p>

                <p><span>Por Que Usar Motion Graphics?:</span> Eles aumentam o engajamento, explicam ideias complexas de forma simples e tornam sua marca mais atrativa.</p>

                <p><span>Aplicações Práticas:</span> Animações para redes sociais, vídeos institucionais, explicações de produtos e campanhas publicitárias.</p>

                <p><span>Tendências em Motion Graphics:</span> Animações 3D, uso de cores vibrantes e transições dinâmicas estão em alta no mercado.</p>

                <p><span>Call-to-Action:</span> Entre em contato para transformar suas ideias em vídeos que encantam e convertem!</p>
            </div>
            <NavPage hide={'3'}></NavPage>
        </div>
    )
}

export default Bpage3;