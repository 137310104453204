import Header from '../components/Header'
import Projetos from '../components/Projetos';

function ProjetosPage() {
    window.scrollTo(0, 0);
    return (
        <div>
            <Header isComunicacao={true}></Header>
            <Projetos />
        </div>
    )
}

export default ProjetosPage;