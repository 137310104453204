import CarouselBootstrap from "../components/Carousel";
import Sobre from "../components/Sobre";
import Identidade from "../components/Identidade";
import Header from '../components/Header'
import Solucoes from "../components/Solucoes";
import Clientes from "../components/Clientes"

function HomePage() {
    return (
        <div>
            <Header></Header>
            <CarouselBootstrap></CarouselBootstrap>
            <Identidade></Identidade>
            <Solucoes></Solucoes>
            <Clientes></Clientes>
            <Sobre></Sobre>

        </div>
    )
}

export default HomePage;