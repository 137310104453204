import Xavier from '../../components/identidades/Xavier';
import Header from '../../components/Header'

function XavierPage() {
    window.scrollTo(0, 0);
    return (
        <div>
            <Header isComunicacao={true}></Header>
            <Xavier />
        </div>
    )
}

export default XavierPage;